export const contactFormInitialValues = {
  subject: "General",
  name: "",
  phone: "",
  email: "",
  message: "",
  attachment: "",
  cardnumber: "",
};

export const loginIntialValues = {
  email: "",
  password: "",
};

export const signupForm = {
  first_name: "",
  last_name: "",
  email: "",
  confirmEmail: "",
  phone: "",
  dob: null,
  address: null,
  password: "",
  confirmPassword: "",
  t_and_c_accepted: false,
  receive_promotions: true,
};

export const resetPasswordInitialValues = {
  password: "",
  confirmPassword: "",
};

export const addCardInitialValues = {
  cardnumber: "",
  ackroo_id: "",
};

export const accountDeletionInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  reason: "",
  t_and_c_accepted: false,
  dateTime: "",
};

export const balanceTransferInitialValues = {
  from_card_number: "",
  to_card_number: "",
  deactivate_source_code: false,
  access_code: "",
};

export const dealerApplicationInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gas_station_owner_or_operator: "",
  address: "",
  message: "",
};

export const realtorApplicationInitialValues = {
  first_name: "",
  last_name: "",
  company_name: "",
  email: "",
  phone: "",
  offering_lease_or_purchase: "",
  address: "",
  size_of_property: "",
  zoning: "",
  attachment: "",
};

export const vendorStepOneInitialValue = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  focused_product: "",
  distribution_model: "",
  company_name: "",
};

export const vendorStepTwoInitialValue = {
  edi_capabilities: "",
  minimum_order_levels: "",
  how_to_order: "",
  product_guarantee: "",
  distribution_reach: "",
  attachment: "",
};

export const fleetMemberStepOneInitialValues = {
  business_name: "",
  operating_as: "",
  business_type: "",
  years_of_company: "",
  years_of_operation: "",
  hst_exempt: "",
  expected_fuel_volume: "",
  no_of_cards_needed: "",
};

export const fleetMemberStepTwoInitialValues = {
  street: "",
  apartment: "",
  city: "",
  province: "",
  postcode: "",
  email: "",
  business_phone: "",
  cell: "",
};

export const fleetMemberStepThreeInitialValues = {
  contact_person: "",
  contact_person_phone: "",
  contact_person_email: "",
};

export const fleetMemberStepFourInitialValues = {
  first_name: "",
  last_name: "",
  dob: "",
  street: "",
  apartment: "",
  city: "",
  province: "",
  postcode: "",
  sin: "",
};

export const fleetMemberStepFiveInitialValues = {
  financial_institution: "",
  branch: "",
  street: "",
  apartment: "",
  city: "",
  province: "",
  first_name: "",
  last_name: "",
  phone: "",
};

export const editUserProfileInitialValues = {
  first_name: "",
  last_name: "",
  dob: "",
  address: "",
  phone: "",
  email: "",
};

export const schoolSpiritInitialValues = {
  school_city: "",
  school_province: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  card_number: "",
  school_name: "",
};

export const surveyFormInitialValues = {
  overall_experience: "0",
  enviornment: "0",
  merchandise: "0",
  service_experience: "0",
  loyalty_card: "0",
  comment: "",
};
